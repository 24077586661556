import React, { useState, useEffect, useRef } from 'react';
import {
  DesktopOutlined,
  FileOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import axios from 'axios';
import {Form, Breadcrumb, Layout, Menu, theme, Image, Space, Flex, Spin, notification } from 'antd';
import Logo from '../../../assets/images/MarshalLogo.png'
import Sider from '../../../components/Sider';
import DataTable from './components/dataSpace';
import SideBar from './components/sideBar';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router';
import FastEnters from '../../../components/fastEnters';
import { useTabsGlobal } from '../components/TabsGlobalProvider';
import saveToLocalStorage from '../../../functions/saveToLocalStorage';
import { UserContext } from '../components/privateroute';
import { useContext } from 'react';
const { Header, Content, Footer } = Layout;


const Sprawa = () => {
  const scrollBox = useRef(null)
  const [name, setName] = useState("")
  const [posts, setPosts] = useState(null)
  const [ticket, setTicket] = useState(null)
  const [loading, setLoading] = useState(true)
  const [logs, setLogs] = useState([])
  const [structures, setStructures] = useState([])
  const [reminders, setReminders] = useState([])
  const [showLogs, setShowLogs] = useState(false)
  const [reload, setReload] = useState(false)
  const { ticketid, postid } = useParams();
  const navigate = useNavigate()
  const [areas, setAreas] = useState([])
  const [special, setSpecial] = useState(null)
  const [specialPeriods, setSpecialPeriods] = useState(null)
  const [loader, setLoader] = useState(false)
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [newPost, setNewPost] = useState({});
  const [fileList, setFileList] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false)
  const [clear, setClear] = useState(false);
  const doesIdExist = (arr, idToCheck) => {
    return arr.some(item => item.id === idToCheck);
  };
  const globalUser = useContext(UserContext)

  const saveCredentialsPost = async (sendType = null) => {
    if(uploadedFiles.length > 0 || newPost.contents != ''){
      // console.log(newPost.contents)
      setSaveLoading(true)
      const formData = new FormData()
      for (let i = 0; i < uploadedFiles.length; i++) {
        formData.append(`files`, uploadedFiles[i]);
      }
      const newdateline = Math.floor(new Date().getTime() / 1000);
      const response = await axios.post("/sprawa/new/post",{
        credentials: {...newPost, dateline: newdateline},
        ticketid: ticket,
        sendtype: sendType
      });
      setTimeout(() => {
        setSaveLoading(false)
      }, 200)
      const postId = response.data.id
      const responseFiles = await axios.post("/sprawa/new/post/files",formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          TicketID: ticket.ticketid,
          PostID: postId
        }
      });
      setFileList([])
      setUploadedFiles([])
      setNewPost({})
      if(response.status == 200){
          notification.success({
              message: response.data.message,
              duration: 3
          });
          setClear(true)
      } else {
          notification.error({
              message: response.data.message,
              duration: 3
          });
      }
    }
    setReload(!reload)
}

  useEffect(() => {
        const fetchData = async () => {
          try {            
            if(Object.keys(globalUser).length > 0) {
            const responseTicket = await axios.post('/sprawa/list/single',{
              id: ticketid,
              userid: globalUser.user.mlg_users_id,
              isadmin: globalUser.isadmin
            });
            const responseSpecial = await axios.post('/specialtime/check',{
              ticketid: ticketid
            });
            const responseSpecialPeriods = await axios.post('/specialtime/get',{
              ticketid: ticketid
            });
            setSpecial(responseSpecial.data)
            setSpecialPeriods(responseSpecialPeriods.data)
            setName(responseTicket.data.subject)
            setTicket(responseTicket.data)
            setLoading(false)
          }
          } catch (error) {
            if(error.response) {
              notification.error({
                message: error.response.data.message,
                duration: 3
              });
              navigate("/sprawy")
            }
          }
        }
        fetchData()
  },[ticketid, reload, globalUser])
  useEffect(() => {
      const fetchData = async () => {
          const response = await axios.post('/sprawa/list/posts',{
            id: ticketid
          });
          setPosts(response.data)
          const responseStructures = await axios.post('/sprawa/list/structure',{
            ticketid: ticketid
          });
          setStructures(responseStructures.data)
          const responselogs = await axios.post('/sprawa/get/logs',{
            ticketid: ticketid
          });
          setLogs(responselogs.data)
          const responseReminders = await axios.post('/sprawa/get/reminders',{
            ticketid: ticketid
          });
          setReminders(responseReminders.data)
          const responseAreas = await axios.post("/sprawa/get/areas",{
            ticketid: ticketid
          });
          setAreas(responseAreas.data)
          setLoader(false)
      }
      fetchData()
      // setTimeout(() => {
      //   if(scrollBox != null && scrollBox.current){
      //     scrollBox.current.scrollTop = scrollBox.current.scrollHeight;
      //   }
      // }, 400)
  },[reload,ticketid])
  return (
    <>
        <Breadcrumb
        style={{
            padding: '20px',
        }}
        >
        <Breadcrumb.Item>Strona główna</Breadcrumb.Item>
        <Breadcrumb.Item onClick={() => navigate("/sprawy")}><span style={{cursor: "pointer"}}>Sprawy</span></Breadcrumb.Item>
        <Breadcrumb.Item>{name}</Breadcrumb.Item>
        </Breadcrumb>
        <FastEnters></FastEnters>
        <Form
          layout="vertical"
          name="ticket"
        >
          <Spin spinning={loading}>
            <Flex className={["fullHeight"]} align="start" style={{gap: "0px", width: '100%', background: "white", height: "calc(-175px + 100vh)"}}>
              {ticket != null && posts != null && reminders != null && special != null && specialPeriods != null ? 
              <><DataTable clear={clear} setClear={setClear} setSaveLoading={setSaveLoading} saveLoading={saveLoading} fileList={fileList} setFileList={setFileList} newPost={newPost} setNewPost={setNewPost} uploadedFiles={uploadedFiles} setUploadedFiles={setUploadedFiles} structures={structures} setStructures={setStructures} postid={postid} specialPeriods={specialPeriods} setReminders={setReminders} reminders={reminders} showLogs={showLogs} setShowLogs={setShowLogs} scrollBox={scrollBox} reload={reload} setReload={setReload} ticket={ticket} setTicket={setTicket} posts={posts} setPosts={setPosts} logs={logs}/>
              <SideBar saveCredentialsPost={saveCredentialsPost} loader={loader} setLoader={setLoader} special={special} setSpecial={setSpecial} areas={areas} setAreas={setAreas} setReload={setReload} reload={reload} showLogs={showLogs} setShowLogs={setShowLogs} ticket={ticket} setTicket={setTicket} posts={posts} setPosts={setPosts}/></>
              : <></>
              }
            </Flex>
          </Spin>
        </Form>
    </>
  );
};
export default Sprawa;