import React, { useState, useEffect } from 'react';
import { Table, Spin, Flex, Badge, Select, Space, Input, Typography, Button as ButtonS, Tooltip, Modal, Progress, Dropdown, Menu } from 'antd';
import { useNavigate } from 'react-router';
import Button from '../../../../../components/Button';
import axios from 'axios';
import {
  SearchOutlined,
  FilterOutlined,
  InfoCircleOutlined,
  PushpinOutlined,
  DownOutlined
} from '@ant-design/icons';
import useDebouncedOnChange from '../../../../../functions/useDebouncedOnChange'
import datetimeParser from '../../../../../functions/datetimeParser';
import CaseStatus from '../../../components/caseStatus';
import SimpleAvatar from '../../../components/simpleAvatar';
import HighlightShort from '../../../../../components/HighlightShort';
import Highlight from '../../../../../components/HighlightShort';
import { UserContext } from '../../../components/privateroute';
import IsFavorite from '../../../components/IsFavorite';
import AdvancedSearching from '../advancedSearching';
import CanWrapper from '../../../../../components/Can';
import UserRaport from '../userRaport';
import saveToLocalStorage from '../../../../../functions/saveToLocalStorage';
const {Text} = Typography

const DataTable = (props) => {
  const {departs, setShowFilters, showFilters} = props
  const globalUser = React.useContext(UserContext);
  const navigate = useNavigate()
  const [data, setData] = useState([]);
  const [user, setUser] = useState(null)
  const [percent, setPercents] = useState(0);
  const [pdfModal, setPdfModal] = useState(false)
  const [open, setOpen] = useState(false);

  const [pagination, setPagination] = useState({
    current: JSON.parse(localStorage.getItem("currentPage")) || 1,
    pageSize: 20,
    total: 0,
    search: "",
    advancedSearch: JSON.parse(localStorage.getItem("advancedSearch")) || null,
    orderby: []
  });
  const [reload, setReload] = useState(false)
  const [loading, setLoading] = useState(false);
  const setSearch = (value) => {
    setPagination({...pagination, search: value})
  }
  const debouncedOnChange = useDebouncedOnChange(setSearch);
  const updateSpecialValue = (newValue) => {
    setPagination(prevPagination => {
      // Check if advancedSearch is not null and contains the 'special' object
      const hasSpecial = prevPagination.advancedSearch && prevPagination.advancedSearch.some(item => item.name === 'special');
      
      let newAdvancedSearch;
      if (hasSpecial) {
        // If the 'special' object exists, update its value to newValue
        newAdvancedSearch = prevPagination.advancedSearch.map(item =>
          item.name === 'special' ? { ...item, value: newValue } : item
        );
      } else {
        // If the 'special' object does not exist, add it with value newValue
        // Also handle the case where advancedSearch is initially null
        newAdvancedSearch = [...(prevPagination.advancedSearch || []), { name: 'special', value: newValue }];
      }
      
      // Return the updated pagination object
      return {
        ...prevPagination,
        advancedSearch: newAdvancedSearch
      };
    });
  };
  
  const fetchData = async (page, pageSize, search, orderby) => {
    try {
      setLoading(true);
      const resperms = await axios.post(process.env.REACT_APP_API_URL+'/perms/get/by/user');
      const userPerms = resperms.data;
      // Replace 'yourApiEndpoint' with your actual server API endpoint
      const response = await axios.post('/sprawy/list/posts', {
        pagination: { ...pagination, current: page, pageSize: pageSize, search: search, orderby},
        departs: departs,
        isadmin: userPerms.includes('can_see_all_tickets')
      });
      setData(response.data.rows);
      setPagination({
        ...pagination,
        total: response.data.total,
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

    // Menu items for the dropdown
    const menu = (
      <Menu>
        <Menu.Item key="1">
          <CanWrapper perms={["can_add_new_tickets"]}>
            <Button onClick={() => navigate("/sprawy/nowa")}>Nowa sprawa</Button>
          </CanWrapper>
        </Menu.Item>
        <Menu.Item key="2">
          <Button
            onClick={() => {
              const specialValue = pagination.advancedSearch?.find(item => item.name === 'special')?.value;
              updateSpecialValue(specialValue === 1 ? 0 : 1);
            }}
            type={pagination.advancedSearch?.find(item => item.name === 'special')?.value === 1 ? 'primary' : 'default'}
            danger={pagination.advancedSearch?.find(item => item.name === 'special')?.value === 1}
          >
            Sprawy specjalne
          </Button>
        </Menu.Item>
        <Menu.Item key="3">
          <AdvancedSearching setPagination={setPagination} pagination={pagination} />
        </Menu.Item>
        <Menu.Item key="4">
          <CanWrapper perms={["can_generate_raports"]}>
            <Button onClick={() => generateRaport()}>Raport PDF</Button>
          </CanWrapper>
        </Menu.Item>
        <Menu.Item key="5">
          <CanWrapper perms={["can_generate_raports"]}>
            <UserRaport />
          </CanWrapper>
        </Menu.Item>
      </Menu>
    );

  const generateRaport = async () => {
    try {
      setPdfModal(true)
      setPercents(0)
      const totalSeconds = Math.ceil(pagination.total / 100);
      const percentIncrement = 100 / totalSeconds;

      const percentInterval = setInterval(() => {
        setPercents((currentPercent) => {
          const updatedPercent = currentPercent + percentIncrement;
          if (updatedPercent >= 100) {
            clearInterval(percentInterval); // Clear interval when reaching 100%
            return 100;
          }
          return Number(updatedPercent.toFixed(2));
        });
      }, 1100); // Update every second
      // Replace 'yourApiEndpoint' with your actual server API endpoint
      const response = await axios.post('/sprawy/list/raport', {
        pagination: pagination,
      });
      axios({
        url: `/files/download/global/${response.data.filename}`, // Replace with your server URL
        method: 'POST',
        responseType: 'blob', // Important
      }).then((response) => {
          const contentDisposition = response.headers['content-disposition'];
          const match = contentDisposition.match(/filename="(.+)"/);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', match[1]); // Replace with filename and extension
          document.body.appendChild(link);
          link.click();
          link.remove();
      }).catch((error) => {
        console.error('Download error:', error);
      });
      clearInterval(percentInterval);
      setPercents(100);
      setPdfModal(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData(pagination.current, pagination.pageSize, pagination.search, pagination.orderby);
  }, [pagination.current, pagination.pageSize, pagination.search, pagination.advancedSearch, reload, departs, pagination.orderby]);

  const handleTableChange = (pagination, filters, sorts) => {
    saveToLocalStorage('currentPage',pagination.current)
    if(sorts.column){
      setPagination({
        ...pagination,
        orderby: [{key: sorts.columnKey, order: sorts.order == 'ascend' ? 'ASC' : 'DESC'}]
      });
    } else {
      setPagination({
        ...pagination,
        orderby: []
      });
    }
  };

  const handlePageSizeChange = (value) => {
    setPagination({
      ...pagination,
      pageSize: value,
      current: 1, // Reset to the first page when changing page size
    });
  };

  const columns = [
    // Define your table columns here
    {
      title:
      <Flex gap="10px" justify='center'>
      <Tooltip style={{cursor: 'pointer'}} placement="topRight" title='Dodaj sprawę do przypiętych spraw aby łatwo mieć do niej dostęp z górnych zakładek.' arrow={{
        pointAtCenter: true,
      }}>
        <PushpinOutlined style={{fontSize: '20px'}} />
      </Tooltip></Flex>,
      dataIndex: 'is_favorite',
      key: 'is_favorite',
      render: (item, record) => {
        return <Flex justify='center'><IsFavorite userid={globalUser.user.mlg_users_id} value={item} ticketid={record.ticketid}></IsFavorite></Flex>
      },
    },
    {
      title: 'ID Sprawy',
      dataIndex: 'ticketid',
      key: 'ticketid',
      render: (item, record) => {
        return <div style={{fontSize: '18px', textAlign: 'right'}} onClick={() => {navigate(`/sprawy/${record.ticketid}`)}}>
          #{record.ticketid}
          </div>
      },
    },
    {
      title: 'Sprawa',
      dataIndex: 'subject',
      key: 'subject',
      render: (item, record) => {
        return <div className={record.watcher_visited == '1' ? 'nonvisited' : ''} style={{display: 'flex', gap: '10px', cursor: "pointer", maxWidth: "1000px", minWidth: "300px"}} onClick={() => {navigate(`/sprawy/${record.ticketid}`)}}>
          {record.ss == '1' ? <div style={{height: "50px", width: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#ff4d4f', fontSize: '18px', fontWeight: '700', color: 'white'}}>SS</div> : <></>}
          {record.watcher_visited == '1' ? <div style={{height: "50px", width: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#3F51B5', fontSize: '18px', fontWeight: '700', color: 'white', padding: '0 10px'}}>N</div> : <></>}
          <div>
          <Text style={{fontSize:'16px', display: "block"}}>{record.priorityid == '4' ? <span style={{color: 'red', fontWeight: '700'}}>!PILNE - </span> : null}{record.priorityid == '3' ? <span style={{color: 'red', fontWeight: '700'}}>!WAŻNE - </span> : null}{item}</Text>
          <Text style={{fontSize:'12px',color: "rgba(0, 0, 0, 0.60)", display: "block"}}>{datetimeParser(record.dateline, 'YYYY-MM-DD HH:mm:ss')}</Text>
          {pagination.search == '' ? <></> : <HighlightShort textContent={record.contents} searchString={pagination.search}/>}
          </div>
          </div>
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (item, record) => {
        return <CaseStatus record={record}/>
      },
    },
    {
      title: 'Ostatnia zmiana',
      dataIndex: 'lastactivity',
      key: 'lastactivity',
      sorter: true,
      render: (item, record) => {
      return datetimeParser(record.lastactivity, 'YYYY-MM-DD HH:mm:ss');
      },
    },
    {
      title: 'Data zdarzenia',
      dataIndex: 'accident_dateline',
      key: 'accident_dateline',
      render: (item, record) => {
        return datetimeParser(record.accident_dateline, 'YYYY-MM-DD')
      },
    },
    {
      title: 'Zgłaszający',
      dataIndex: 'phone',
      key: 'phone',
      render: (item, record) => {
        return <SimpleAvatar user={record.userDetails}></SimpleAvatar>
      }
    },
    {
      title: 'Przypisany',
      dataIndex: 'przypisany',
      key: 'przypisany',
      render: (item, record) => {
        return <SimpleAvatar user={record.userDetailsPrzypisany}></SimpleAvatar>
      }
    },
  ];

  return (
    <>
    <div>
    <div className='smallResCol' size="large" style={{display: 'flex', width:'100%', gap: "10px", padding: "10px 20px 0px 20px"}}>
      <Input onChange={debouncedOnChange} disabled={loading} addonBefore={<SearchOutlined />} placeholder="Wyszukaj sprawę | #ID, @tag, Tytuł, treść, województwo, powiat, gmina" />
      <div className='hideMobile' style={{display: 'flex', width:'100%', gap: "10px"}}>
      <CanWrapper perms={["can_add_new_tickets"]}><Button style={{}} onClick={() => navigate("/sprawy/nowa")}>Nowa sprawa</Button></CanWrapper>
      <Button style={{}} onClick={() => {
        pagination.advancedSearch?.find(item => item.name === 'special')?.value == 1 ? updateSpecialValue(0) : updateSpecialValue(1)
      }} type={pagination.advancedSearch?.find(item => item.name === 'special')?.value == 1 ? 'primary' : 'default'} danger={pagination.advancedSearch?.find(item => item.name === 'special')?.value == 1}>Sprawy specjalne</Button>
      <AdvancedSearching setPagination={setPagination} pagination={pagination}></AdvancedSearching>
      <CanWrapper perms={["can_generate_raports"]}><Button style={{}} onClick={() => generateRaport()}>Raport PDF</Button></CanWrapper>
      <CanWrapper perms={["can_generate_raports"]}><UserRaport></UserRaport></CanWrapper>
      </div>
      <Modal
        centered={true}
        open={pdfModal}
        title="Generowanie raportu PDF"
        onOk={() => {}}
        onCancel={() => {}}
        footer={[]}
      >
        <Progress percent={percent} type="line" />
      </Modal>
      <Dropdown className='showMobile' overlay={menu} trigger={["click"]} onOpenChange={(flag) => setOpen(flag)} open={open}>
        <Button>
          Akcje i filtry <DownOutlined />
        </Button>
      </Dropdown>
      {/* <ButtonS icon={<FilterOutlined />} className='shopOn800px' style={{width: "40px"}} onClick={() => setShowFilters(!showFilters)}></ButtonS> */}
    </div>
    <div className='overflowTable' style={{padding: "0px 20px"}}>
    <Spin spinning={loading}>
      <Table
      size="small"
      style={{width:'100%', gap: "10px", marginTop: '20px'}}
        columns={columns}
        dataSource={data}
        pagination={{
          ...pagination,
          position: ["topRight", "bottomRight"],
          showSizeChanger: true,
          pageSizeOptions: ['20', '40', '50'],
          onShowSizeChange: handlePageSizeChange,
          showQuickJumper: true,
          showTotal: (total, range) => `${range[0]}-${range[1]} z ${total} elementów`, // Customize total display
          locale: {
            items_per_page: '/ stronę', // Customize the text in the select dropdown
          },
        }}
        onChange={handleTableChange}
        rowKey={(record) => record.id}
        locale={{ emptyText: 'Brak danych' }}
        onRow={(record) => ({
          style: {
            opacity: record.statusopacity ? Math.min(Math.max(record.statusopacity, 0), 1) : 1,
          },
        })}
      />
    </Spin>
    </div>
    </div>
    </>
  );
};

export default DataTable;