import React,{ useState, useEffect, useRef } from 'react';
import { Button, Table, Spin, Flex, Badge, Select, Space, Input, Typography, notification, Form } from 'antd';
import { useNavigate } from 'react-router';
// import Button from '../../../../../components/Button';
import axios from 'axios';
import {
  SearchOutlined
} from '@ant-design/icons';
import useDebouncedOnChange from '../../../../../functions/useDebouncedOnChange'
import datetimeParser from '../../../../../functions/datetimeParser';
import CaseStatus from '../../../components/caseStatus';
import SimpleAvatar from '../../../components/simpleAvatar';
import HighlightShort from '../../../../../components/HighlightShort';
import Highlight from '../../../../../components/HighlightShort';
import TitlePost from '../PostAsTitle';
import ContentPost from '../PostAsContent';
import ReactQuill from 'react-quill';
import { useParams } from 'react-router';
import 'react-quill/dist/quill.snow.css';
import { UserContext } from '../../../components/privateroute';
import LogsAsContent from '../LogAsContent';
import { merge } from 'antd/es/theme/util/statistic';
import { UploadOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import TextEditor from '../../../components/textEditor';
import Reminder from '../../../components/reminder';
import ReminderPost from '../PostAsReminder';
import CanWrapper from '../../../../../components/Can';
import SendNotifications from '../sendNotifications';
import AddStructure from '../addStructure';
import StructurePost from '../PostAsStructure';
const fileList = [];
const {Text} = Typography

const DataTable = (props) => {
  const [endOfRender, setEndOfRender] = useState(false);
  const {clear, setClear, saveLoading, setSaveLoading, fileList, setFileList, newPost, setNewPost, uploadedFiles, setUploadedFiles, posts, reload, setPosts, ticket, setTicket, setReload, scrollBox, logs, showLogs, setShowLogs, reminders, specialPeriods, postid, structures, setStructures} = props
  const globalUser = React.useContext(UserContext);
  const [order, setOrder] = useState(0)
  const [merge, setMerge] = useState([...posts, ...logs].sort((a, b) => a.dateline - b.dateline))
  const saveContents = (value) => {
    setNewPost(
      {
        ...newPost,
        contents: value
      }
    )  
  }

  useEffect(() => {
    setNewPost({
      ticketid: ticket.ticketid,
      dateline: Math.floor(new Date().getTime() / 1000),
      userid: globalUser.user.mlg_users_id,
      fullname: globalUser.user.mlg_users_fullname,
      email: globalUser.contacts.find(contact => contact.type === 'E-mail')?.value,
      subject: ticket.subject,
      contents: '', // Initialize contents property
    });
  }, [posts, ticket]);

  useEffect(() => {
    if(posts && logs && reminders && structures){
      if(order == 0){
        setMerge([...posts, ...logs, ...reminders, ...structures].sort((a, b) => b.dateline - a.dateline));
      } else {
        setMerge([...posts, ...logs, ...reminders, ...structures].sort((a, b) => a.dateline - b.dateline));
      }
    }
  },[reload, posts, logs, reminders, structures, order])


  const customAction = (file) => {
    setUploadedFiles([...uploadedFiles, file]);
    setFileList([...fileList, file]);
    return new Promise((resolve) => {
      // Simulating a successful upload response after a delay
      setTimeout(() => {
        resolve({ success: true });
      }, 2000); // Simulating a delay of 2 seconds
    });
  };

  const scrollToPost = (postId) => {
    setTimeout(() => {
      const element = document.getElementById(`post${postId}`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 500)
  };

  useEffect(() => {
      if (postid) { // Assuming `postid` is the ID of the post to scroll to
        scrollToPost(postid);
        document.getElementById(`post${postid}`).style.background = "#e5e5e5"
      }
    }, [endOfRender]);


      // Function to handle file drop
  const handleFileDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const files = Array.from(event.dataTransfer.files).map(file =>
      Object.assign(file, {
        uid: Date.now() + Math.random() // ensure a unique key
      })
    );

    // Set the files in the existing upload component state
    setUploadedFiles(prevFiles => [...prevFiles, ...files]);
  };

  // Function to handle drag over to prevent default behavior
  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);

    // Update uploadedFiles state to reflect the current fileList
    // This assumes fileList contains the current state of files, including any additions or removals
    const updatedUploadedFiles = newFileList.map(file => file.originFileObj).filter(Boolean);
    setUploadedFiles(updatedUploadedFiles);
};
  
  const beforeUploadHandler = (file) => {
    // Perform your custom action here
    customAction(file).then((response) => {
    });
    // Returning false prevents the default upload behavior
    return false;
  };

  return (
    <>
    <div style={{width: "calc(100% - 300px)"}}>
    <div ref={scrollBox} style={{ overflowY: "auto", width: "100%", height: "calc(100% - 300px)", padding: "0 20px"}}>
      <TitlePost data={ticket} order={order} setOrder={setOrder}></TitlePost>
      {posts && logs && reminders && structures ? merge.map((x) => {
        if(x.ticketauditlogid){
          return <LogsAsContent specialPeriods={specialPeriods} data={x} showLogs={showLogs}></LogsAsContent>
        } else if(x.sweventsid) {
          return <ReminderPost specialPeriods={specialPeriods} setReload={setReload} reload={setReload} showLogs={showLogs} data={x}></ReminderPost>
        } else if(x.idstructure) {
          return <StructurePost ticketid={ticket.ticketid} userid={globalUser.user.mlg_users_id} specialPeriods={specialPeriods} setReload={setReload} reload={setReload} showLogs={showLogs} data={x}></StructurePost>
        } else {
          return <ContentPost postid={postid} endOfRender={endOfRender} setEndOfRender={setEndOfRender} specialPeriods={specialPeriods} data={x}></ContentPost>
        }
      }) : <></>}
    </div>
    <CanWrapper perms={['can_add_posts']}> 
    <div className='editticket'
         onDrop={handleFileDrop}
         onDragOver={handleDragOver}
    style={{position:"relative", borderTop: "1px solid black", height: "300px"}}>
      <div className='openingEditing'></div>
      <div style={{
        display: "flex",
        justifyContent: "flex-start",
        padding: "10px 20px",
        gap: "10px",
        flexWrap: 'wrap'
      }}>
        <Upload
          multiple={true}
          action={customAction}
          beforeUpload={beforeUploadHandler}
          listType="picture"
          fileList={uploadedFiles}
          className="upload-list-inline"
          onChange={handleChange}
        >
        {/* <SendNotifications styleButton={{width: 'auto'}} saveCredentials={saveCredentials} saveLoading={saveLoading} setSaveLoading={setSaveLoading}></SendNotifications> */}
        <Button icon={<UploadOutlined />}>Wgraj nowy plik</Button>
        </Upload>
        <AddStructure setReload={setReload} reload={reload}></AddStructure>
        <Reminder reload={reload} setReload={setReload} ticketid={ticket.ticketid}></Reminder>
      </div>
      <div 
      style={{padding: "0px 20px", height: "50%"}}
      >
      <TextEditor setClear={setClear} clear={clear} value={newPost.contents} onChange={(e) => saveContents(e)}></TextEditor>
      </div>
    </div>
    </CanWrapper>
    </div>
    </>
  );
};

export default DataTable;