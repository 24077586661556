import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {Form, Tree, Space, Card, Typography, Select, Avatar, Modal, Button, notification } from 'antd';
import { UserOutlined, ShareAltOutlined, CopyOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import datetimeParser from '../../../../../functions/datetimeParser';
import DOMPurify from 'dompurify';
import UrlPreview from '../../../components/urlPreview';
import FilePreview from '../../../components/filePreview';
const {Text, Title} = Typography

function extractUrlsFromText(htmlOrText) {
    // Decode HTML entities
    const regex = /<[^>]*>/g;
    let text = new DOMParser().parseFromString(htmlOrText.replace(regex, ' '), 'text/html').body.textContent || "";
    // Improved regex to match URLs more inclusively, handling different text formats
    // This pattern matches the start of a URL (http/https) and continues until it hits a space or end of line, accounting for various terminators
    const urlRegex = /https?:\/\/[^\s]+/gi;
  
    // Extracting the URLs
    let matches, urls = [];
    while ((matches = urlRegex.exec(text)) !== null) {
        // This avoids pushing duplicates
        if (urls.indexOf(matches[0]) === -1) {
            urls.push(matches[0]);
        }
    }
    // Return an array of URLs or an empty array if no URLs are found
    return urls;
}


function convertUrlsToLinks(text) {
    if(text){
        var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    
        // First, we'll split the text by the anchor tags
        const parts = text.split(/(<a\b[^>]*>.*?<\/a>)/ig);
    
        for (let i = 0; i < parts.length; i++) {
            // Check if the current part is not an anchor tag
            if (!parts[i].startsWith('<a')) {
                // Replace URLs in this part
                parts[i] = parts[i].replace(urlRegex, function(url) {
                    return '<a href="' + url + '" target="_blank">' + url + '</a>';
                });
            }
        }
    
        // Join the parts back together
        return parts.join('');
    }
    return ''
}

function isDatelineInRange(objects, dateline) {
    if(objects.length > 0){
        for (let obj of objects) {
          // Check if dateline_end is null
          if (obj.dateline_end === null) {
            // Check if the given dateline is greater than dateline_start
            if (dateline > obj.dateline_start) {
              return true;
            }
          } else {
            // Check if the given dateline is between dateline_start and dateline_end
            if (dateline >= obj.dateline_start && dateline <= obj.dateline_end) {
              return true;
            }
          }
        }
        // If none of the objects match the criteria, return false
        return false;
    } else {
        return false
    }
}


function convertIdsToLinks(htmlString, navigateToUrl) {
    const regex = /#(\d+)/g;
    const result = htmlString.replace(regex, `<a href="/#/sprawy/$1">#$1</a>`);
    return result;
  }

const copyTextToClipboard = async (text) => {
  try {
    await navigator.clipboard.writeText(text);
    notification.info({
        message: `Skopiowano link do schowka.`,
        placement: 'bottomRight'
    });
  } catch (err) {
  }
};

const ContentPost = (props) => {
    const {data, specialPeriods, setEndOfRender, endOfRender, postid} = props
    const urls = extractUrlsFromText(data.contents)
    const cleanHtml = DOMPurify.sanitize(convertUrlsToLinks(data.contents));
    const outputHtml = convertIdsToLinks(cleanHtml);
    // console.log(cleanHtml)
    const [modalOpen, setModalOpen] = useState(false)
    return (
        <div id={'post'+data.ticketpostid} className='post' style={isDatelineInRange(specialPeriods, data.dateline) ? {display: "grid", gridTemplateColumns: "46px auto", columnGap: "20px",padding: "20px 0px", borderBottom: "1px solid #D3D3D3", background: '#f9dbdb', paddingLeft: '15px', borderLeft: '3px solid red', background: postid == data.ticketpostid ? 'red !important' : '',} : {display: "grid", gridTemplateColumns: "46px auto", columnGap: "20px",padding: "20px 0px", borderBottom: "1px solid #D3D3D3", background: postid == data.ticketpostid ? 'red !important' : '',} }>
            <div className='post row first'>
                <Avatar style={{backgroundColor: "black", textTransform: "uppercase", borderRadius: "5px"}} shape="square" size={46}>{data.userDetails ? data.userDetails.mlg_users_fullname[0] + data.userDetails.mlg_users_fullname[1]: "??"}</Avatar>
            </div>
            <div className='post row second'>
            <Modal
                centered={true}
                open={modalOpen}
                title="Skopiuj szybki link do wpisu"
                onCancel={() => setModalOpen(false)}
                footer={[]}
            >
                <div style={{display: 'flex'}} onClick={() => copyTextToClipboard(`${window.location.hostname}/#/sprawy/${data.ticketid}/${data.ticketpostid}`)}>
                    <div style={{padding: '10px', border: '1px solid black'}}>
                        <CopyOutlined />
                    </div>
                    <div style={{padding: '10px', border: '1px solid black', borderLeft: '0px', width: '100%'}}>
                        {window.location.hostname}/#/sprawy/{data.ticketid}/{data.ticketpostid}
                    </div>
                </div>
            </Modal>
                <div>
                <Title style={{margin: "0px"}} level={5}>{data.userDetails ? data.userDetails.mlg_users_fullname: ""}</Title>
                <Text style={{margin: "0px", color: "#9B9B9B"}}><Button onClick={() => setModalOpen(true)}><ShareAltOutlined /></Button> #{data.ticketpostid} | {datetimeParser(data.dateline, 'YYYY-MM-DD HH:mm:ss')} {data.isemailed == 1 ? <>via e-mail <Text style={{color: "#656565"}}>{data.email}</Text></> : <></>}</Text>
                </div>
                <div style={{marginTop: "20px"}}>
                <div dangerouslySetInnerHTML={{ __html: outputHtml }} />
                </div>
                <div style={{marginTop: "20px"}}>
                <div style={{display: "flex", gap: "10px", flexWrap: "wrap"}}>
                    {data.files ? data.files.map((x) => {
                        return <FilePreview data={x}></FilePreview>
                    }) : <></>}
                </div>
                
                {urls.map((x) => {
                    return <UrlPreview url={x}></UrlPreview>
                })}
                </div>
            </div>
        </div>
    );
};
export default ContentPost;