import React, { useState } from 'react';
import { Breadcrumb, Layout, Menu, theme, Image, Space, Flex } from 'antd';
import SideFilter from './components/sideFilter';
import DataTable from './components/dataTable';
import FastEnters from '../../../components/fastEnters';
const Sprawy = () => {
  const [departs, setDeparts] = useState([])
  const [showFilters, setShowFilters] = useState(false)
  return (
    <>
        <Breadcrumb
        style={{
            padding: '20px',
        }}
        >
        <Breadcrumb.Item>Strona główna</Breadcrumb.Item>
        <Breadcrumb.Item>Sprawy</Breadcrumb.Item>
        </Breadcrumb>
        <FastEnters></FastEnters>
        {/* <div className={["fullHeight, twoColumnsLayout"]} align="start" style={{gap: "0px", width: '100%', background: "white", height: "100%", display: "grid", gridTemplateColumns: "300px calc(100% - 300px)"}}> */}
        <div className={["fullHeight, twoColumnsLayout"]} align="start" style={{gap: "0px", width: '100%', background: "white", height: "100%", display: "grid"}}>
          {/* <SideFilter setDeparts={setDeparts} setShowFilters={setShowFilters} showFilters={showFilters}/> */}
          <DataTable departs={departs} setShowFilters={setShowFilters} showFilters={showFilters}/>
        </div>
    </>
  );
};
export default Sprawy;