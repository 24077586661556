import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {Form, Tree, Space, Card, Typography, Select, Avatar, Image, Spin } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import {AlertOutlined, DeleteOutlined} from '@ant-design/icons';
import datetimeParser from '../../../../../functions/datetimeParser';
import DOMPurify from 'dompurify';
import UrlPreview from '../../../components/urlPreview';
import FilePreview from '../../../components/filePreview';
import ThumbUp from '../../../../../assets/images/thumbs-up.svg'
import ThumbDown from '../../../../../assets/images/thumbs-down.svg'
const {Text, Title} = Typography

const StructurePost = (props) => {
    const {ticketid, userid, data, showLogs, reload, setReload} = props
    const [loading, setLoading] = useState(false)
    const changeUp = async () => {
        setLoading(true)
        const response = await axios.post('/sprawa/structure/change',{
            userid: userid,
            idstructure: data.idstructure,
            opinion: 2,
            text: data.text,
            type: data.type,
            ticketid: ticketid
        }) 
        setLoading(false)
        setReload(new Date().getTime())
    }
    const changeDown = async () => {
        setLoading(true)
        const response = await axios.post('/sprawa/structure/change',{
            userid: userid,
            idstructure: data.idstructure,
            opinion: 1,
            text: data.text,
            type: data.type,
            ticketid: ticketid
        }) 
        setLoading(false)
        setReload(new Date().getTime())
    }
    const changeReset = async () => {
        setLoading(true)
        const response = await axios.post('/sprawa/structure/change',{
            userid: userid,
            idstructure: data.idstructure,
            opinion: 0,
            text: data.text,
            type: data.type,
            ticketid: ticketid
        }) 
        setLoading(false)
        setReload(new Date().getTime())
    }
    return (
        <>
        <div className='post' style={{display: "grid", gridTemplateColumns: "46px auto", columnGap: "20px",padding: "20px 0px", borderBottom: "1px solid #D3D3D3"}}>
            <div className='post row first'>
                <Avatar style={{backgroundColor: "black", textTransform: "uppercase", borderRadius: "5px"}} shape="square" size={46}>{data.userDetails ? data.userDetails.mlg_users_fullname[0] + data.userDetails.mlg_users_fullname[1]: "??"}</Avatar>
            </div>
            <div className='post row second'>
                <div>
                <Title style={{margin: "0px"}} level={5}>{data.userDetails ? data.userDetails.mlg_users_fullname: ""}</Title>
                <Text style={{margin: "0px", color: "#9B9B9B"}}>{datetimeParser(data.dateline, 'YYYY-MM-DD HH:mm:ss')}</Text>
                </div>
                <div style={{marginTop: "20px"}}>
                </div>
                <span style={{display: 'block', marginBottom: '10px'}}>{data.userDetails.mlg_users_fullname} utworzył/a nową informację</span>
                <div style={{
                    display: "flex",
                    boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.25)",
                    background: 'white',
                    borderRadius: '4px',
                    width: 'fit-content'
                }}>
                    <div style={{padding: "10px 20px", maxWidth: '400px', minWidth: 'min(300px)', background: (data.opinion == 0 ? '#ffb6002e' : data.opinion == 1 ? '#ff00002e' : '#00ff522e')}}>
                        <h3 style={{margin: '0px'}}>{data.type == 1 ? 'Adres' : ''}{data.type == 2 ? 'Numer telefonu' : ''}{data.type == 3 ? 'Notatka' : ''}</h3>
                        <p>{data.text}</p>
                        <p>{data.note != 'undefined' && data.note ? (data.type == 1 || data.type == 2 ? data.note : '') : ''}</p>
                        {data.type == 1 ? <>
                        <a target='_blank' src={data.link} onClick={() => window.open(data.link, '_blank')}>Pokaż trasę</a>
                        </> : <></>}
                    </div>
                    
                    <div style={{
                        background: 'rgba(189, 129, 54, 0.20)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '15px',
                        flexDirection: 'row',
                        padding: '10px'
                    }}>
                        <Spin spinning={loading}>
                    <div onClick={() => data.opinion == 1 ? changeReset() : changeDown()}><Image style={{padding: '10px', background: 'white', borderRadius: '30px', cursor: 'pointer', opacity: (data.opinion == 1 ? '1' : '0.2')}} preview={false} src={ThumbDown}></Image></div>
                    </Spin>
                    <Spin spinning={loading}>
                    <div onClick={() => data.opinion == 2 ? changeReset() : changeUp()}><Image style={{padding: '10px', background: 'white', borderRadius: '30px', cursor: 'pointer', opacity: (data.opinion == 2 ? '1' : '0.2')}} preview={false} src={ThumbUp}></Image></div>
                    </Spin>
                    </div>
                </div>
            </div>
        </div></>
    );
};
export default StructurePost;