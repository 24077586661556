import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {Form, Tree, Space, Card, Typography, Select, Avatar, Button} from 'antd';
import { UserOutlined } from '@ant-design/icons';
import datetimeParser from '../../../../../functions/datetimeParser';
import { useNavigate } from 'react-router-dom';
const {Text, Title} = Typography

const TitlePost = (props) => {
    const {data, order, setOrder} = props
    const navigate = useNavigate()
    return (
        <div className='post' style={{display: "grid", gridTemplateColumns: "46px auto", columnGap: "20px",padding: "20px 0px", borderBottom: "1px solid #D3D3D3"}}>
            <div className='post row first'>
                {data.userDetails ? <Avatar style={{backgroundColor: "black", borderRadius: "5px", textTransform: "uppercase"}} shape="square" size={46}>{data.userDetails.mlg_users_fullname[0]}{data.userDetails.mlg_users_fullname[1]}</Avatar> : <></>}
            </div>
            <div className='post row second'>
                <Title style={{margin: "0px"}} level={4}>#{data.ticketid} {data.subject}</Title>
                <Title style={{margin: "0px"}} level={4}>{data.accident_dateline && data.accident_dateline != 'null' ? `Data zdarzenia: ${datetimeParser(data.accident_dateline, 'YYYY-MM-DD')}` : ``}</Title>
                <Text style={{margin: "0px", color: "#9B9B9B"}}>{datetimeParser(data.dateline, 'YYYY-MM-DD HH:mm:ss')} {data.isemailed == 1 ? <>via e-mail <Text style={{color: "#656565"}}>{data.email}</Text></> : <></>}</Text>
                <div style={{marginTop: '10px'}}>
                    {data.parentstickets.length > 0 ? 
                    <>
                    <div>Sprawy powiązane</div>
                    <div style={{display: 'flex', gap: '10px', flexWrap: 'wrap'}}>
                    {data.parentstickets ? data.parentstickets.map((item, index) => {
                       return <div onClick={() => navigate('/sprawy/'+item.ticketid)} style={{
                            padding: '5px',
                            border: '1px solid #bababa',
                            borderRadius: '5px',
                            background: '#f6f6f6',
                            cursor: 'pointer'
                        }}>
                            <div style={{maxWidth: '250px',textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}><span style={{fontWeight: '700'}}>#{item.ticketid}</span> | {item.subject}</div>
                        </div>
                    }) : <></>}
                    </div>
                    </>
                    : <></>}
                </div>
                <Button onClick={() => setOrder(!order)}>{order ? 'Sortuj od najnowszych' : 'Sortuj od najstarszych'}</Button>
            </div>
        </div>
    );
};
export default TitlePost;