/**
 * The above code is a React component that represents the layout of an application, including a
 * sidebar menu, header, and content area.
 * @param label - The label is the name or title of the menu item. It is displayed as the text for the
 * menu item.
 * @param key - The `key` parameter is a unique identifier for each menu item. It is used to identify
 * the selected menu item when a menu item is clicked.
 * @param icon - The `icon` parameter is an Ant Design icon component that represents the icon to be
 * displayed for the menu item. In the code snippet, it is used to render icons for the "Sprawy" and
 * "Kalendarz" menu items. The `ProfileOutlined` and `CalendarOutlined` icons
 * @param children - The `children` parameter in the `getItem` function is used to specify any child
 * elements that should be rendered under the menu item. In the given code, the `children` parameter is
 * set to `null` for all menu items. This means that no child elements will be rendered under the menu
 * @param [url=null] - The `url` parameter is used to specify the URL path for each menu item. It is
 * used to navigate to a specific page when the menu item is clicked.
 * @returns The `AppLayout` component is being returned.
 */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  ProfileOutlined,
  CalendarOutlined,
  SettingOutlined,
  HighlightOutlined
} from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, theme, Image, Space, Flex, Drawer, Tooltip, Button, Avatar, Badge } from 'antd';
import AvatarUser from '../avatar';
import { useNavigate } from 'react-router';
import LogoutButton from '../../login/components/logout';
import Logo from '../../../../assets/images/MarshalLogo.png';
import LogoMini from '../../../../assets/images/MarshalMiniLogo.png';
import Idle from '../idletimer';
import isCan from '../../../../components/isCan'
import { useLocation } from 'react-router-dom';
import { MenuOutlined, AlertOutlined } from '@ant-design/icons';
import { TabsGlobalProvider } from '../TabsGlobalProvider';
import NotificationBox from '../NotificationBox';
import perms from '../../../../config/perms';
const { Header, Content, Footer, Sider } = Layout;

async function getItem(label, key, icon, children, url = null, permurl = null) {
  try {
    return await isCan(perms[permurl].permissions) ? {
      key,
      icon,
      children,
      label,
      url
    } : null;
  } catch (error) {
    return {
      key,
      icon,
      children,
      label,
      url
    };    
  }
}

const items = [
  await getItem('Sprawy', '/sprawy', <ProfileOutlined />, null, '/sprawy', '/sprawy'),
  await getItem('Notatnik', '/notatnik', <HighlightOutlined />, null, '/notatnik', '/notatnik'),
  await getItem('Ustawienia aplikacji', '/ustawienia', <SettingOutlined />, null, '/ustawienia', '/ustawienia'),
  await getItem('Tagi', '/ustawienia/tagi', <SettingOutlined />, null, '/ustawienia/tagi', '/ustawienia'),
  await getItem('Pola dodatkowe', '/ustawienia/pola', <SettingOutlined />, null, '/ustawienia/pola', '/ustawienia'),
];

const AppLayout = (props) => {
  const navigate = useNavigate()
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [opened, setOpened] = useState(false);
  const [currentTicket, setCurrentTicket] = useState()

  const handleMenuClick = ({ key }) => {
    const selectedItem = items.find((item) => item.key === key);
    if (selectedItem && selectedItem.url) {
      setOpened(false)
      navigate(selectedItem.url)
    }
  };

  const onClose = () => {
    setOpened(false);
  };

  const { content } = props;

  return (
    <TabsGlobalProvider>
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      {/* <Idle></Idle> */}
      <Drawer title="" placement="right" onClose={onClose} open={opened}>
        <Space style={{ padding: '20px' }}>
          <Image preview={false} src={!collapsed ? Logo : LogoMini}></Image>
        </Space>

        <Menu
          selectedKeys={[location.pathname]}
          defaultSelectedKeys={['']}
          mode="inline"
          items={items}
          onClick={handleMenuClick}
        />
      </Drawer>
      <Sider
      className='sider'
        style={{ background: 'white' , width: "400px", boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.15)"}}
        collapsible
        width="250px"
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <Space style={{ padding: '20px' }}>
          <Image preview={false} src={!collapsed ? Logo : LogoMini}></Image>
        </Space>

        <Menu
          selectedKeys={[location.pathname]}
          mode="inline"
          items={items}
          onClick={handleMenuClick}
        />
      </Sider>
      <Layout>
        <Header
          style={{
            padding: "0 20px",
            background: 'white',
            display: "flex",
            gap: "15px",
            justifyContent: "flex-end",
            alignItems: 'center'
          }}
        >
          <Button onClick={() => {setOpened(true)}} className='mobilemenu' icon={<MenuOutlined />} />
          <NotificationBox></NotificationBox>
          <AvatarUser></AvatarUser>
        </Header>
        <Content
          style={{
            margin: '0 0px',
          }}
        >
          {content}
        </Content>
      </Layout>
    </Layout>
    </TabsGlobalProvider>
  );
};

export default AppLayout;
