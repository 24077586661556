import React from 'react';

const HighlightShort = ({ textContent, searchString }) => {
  const highlightFirstMatch = (text, search) => {
    if(text){
      const index = text.toLowerCase().indexOf(search.toLowerCase());
      if (index === -1) return false; // Search term not found, return false
  
      // Get 50 characters before and after the search term, or as many as are available if near the start or end
      const start = Math.max(0, index - 100);
      const end = Math.min(text.length, index + search.length + 300);
      const snippet = text.slice(start, end);
  
      // Highlight the search term within the snippet
      return snippet.replace(new RegExp(`(${search})`, 'gi'), `<span style="background-color: yellow;">$1</span>`);
    } else {
      return text
    }
  };

  const highlightedText = highlightFirstMatch(textContent, searchString);

  if (!highlightedText) {
    return null; // Or handle the "not found" case as needed
  }

  return <span dangerouslySetInnerHTML={{ __html: highlightedText }} />;
};

export default HighlightShort;
