import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {Form, Tree, Space, Card, Typography, Select, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import {AlertOutlined, DeleteOutlined} from '@ant-design/icons';
import datetimeParser from '../../../../../functions/datetimeParser';
import DOMPurify from 'dompurify';
import UrlPreview from '../../../components/urlPreview';
import FilePreview from '../../../components/filePreview';
const {Text, Title} = Typography

const ReminderPost = (props) => {
    const {data, showLogs, reload, setReload} = props
    const [removed, setRemoved] = useState(false)
    const datetime = Math.floor(new Date().getTime() / 1000)
    const removeReminder = async (id, ticketid) => {
        const responseDelete = await axios.post("/sprawa/remove/reminder",{
            id: id,
            ticketid: ticketid
        });
        setRemoved(true)
    }
    return (
        <>
        <div className='post' style={{display: (showLogs || datetime <= data.startdateline) && !removed ?  "grid" : 'none', gridTemplateColumns: "46px auto", columnGap: "20px",padding: "20px 0px", borderBottom: "1px solid #D3D3D3"}}>
            <div className='post row first'>
                <Avatar style={{backgroundColor: "black", textTransform: "uppercase", borderRadius: "5px"}} shape="square" size={46}>{data.userDetails ? data.userDetails.mlg_users_fullname[0] + data.userDetails.mlg_users_fullname[1]: "??"}</Avatar>
            </div>
            <div className='post row second'>
                <div>
                <Title style={{margin: "0px"}} level={5}>{data.userDetails ? data.userDetails.mlg_users_fullname: ""}</Title>
                <Text style={{margin: "0px", color: "#9B9B9B"}}>{datetimeParser(data.created_at, 'YYYY-MM-DD HH:mm:ss')}</Text>
                </div>
                <div style={{marginTop: "20px"}}>
                </div>
                <span style={{display: 'block', marginBottom: '10px'}}>{data.userDetails.mlg_users_fullname} utworzył/a przypomnienie</span>
                <div style={{
                    display: "flex",
                    boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.25)",
                    background: 'white',
                    borderRadius: '4px',
                    width: 'fit-content'
                }}>
                    <div style={{
                        background: 'rgba(222, 0, 0, 0.20)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        padding: '10px',
                        cursor: 'pointer',
                    }}
                    onClick={() => removeReminder(data.sweventsid, data.ticketid)}
                    >
                        <DeleteOutlined style={{fontSize: '20px'}}></DeleteOutlined>
                    </div>
                    <div style={{padding: "10px 20px", maxWidth: '400px', minWidth: 'min(300px)'}}>
                        <h3 style={{margin: '0px'}}>{data.eventname}</h3>
                    </div>
                    <div style={{
                        background: 'rgba(189, 129, 54, 0.20)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        padding: '10px'
                    }}>
                     <AlertOutlined style={{fontSize: '25px'}}></AlertOutlined>   
                     <span style={{fontSize: '16px', fontWeight: '700', maxWidth: '100px', textAlign: 'center'}}>{datetimeParser(data.startdateline, 'YYYY-MM-DD HH:mm')}</span>
                    </div>
                </div>
            </div>
        </div></>
    );
};
export default ReminderPost;